import * as Yup from 'yup';
import React, { useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Box, Typography, FormHelperText, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import MachineListAPIs from '../../../utilities/api/machine/AdminMachineList';
import CustomLocationDialog from './CustomLocation';
import CustomCategoryDialog from './CustomCategory';
import InventoryLocationAPIs from '../../../utilities/api/location/AdminLocationList';
import InventoryCategoryAPIs from '../../../utilities/api/category/AdminInventoryCategoryList';
import FormField from '../../shared/FormField';
import { toast } from 'react-toastify';

const availabilityList = ['Very Low', 'Low', 'Moderate', 'High', 'Always'];

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Product type is required'),
  // upc: Yup.number().required('UPC is required'),
  upc: Yup.number(),
  instances: Yup.number().required('Instances is required'),
  // description: Yup.string().required('Description is required'),
  description: Yup.string(),
  category: Yup.string(),
  location: Yup.string(),
  availability: Yup.string(),
  manufacturer: Yup.string(),
  purchase_cost: Yup.number().typeError('A valid number is required'),
  image: Yup.mixed().nullable(),
  purchase_date: Yup.date().nullable().typeError('Date has wrong format. Use YYYY-MM-DD'),
  warranty_expiration: Yup.date().nullable().typeError('Date has wrong format. Use YYYY-MM-DD'),
});

const styledField = {
  maxWidth: '70%',
  padding: '0px 4px 8px 6px',
};

const AddMachineDialog = ({ open, onClose, update, setUpdate, flag, setFlag }) => {
  const user = useSelector(state => state.user.user);
  const token = user.access;

  const [openCustomLocation, setOpenCustomLocation] = useState(false);
  const [customLocation, setCustomLocation] = useState('');
  const [openCustomCategory, setOpenCustomCategory] = useState(false);
  const [customCategory, setCustomCategory] = useState('');
  const [newImage, setNewImage] = useState(null);
  const fileInputRef = useRef(null);

  const category = useSelector(state => state.category.category_list);
  const location = useSelector(state => state.location.location_list);

  const categories = category.map(item => item.name);
  const locations = location.map(item => item.name);

  const handleConfirmLocation = () => {
    setOpenCustomLocation(false);
    const response = InventoryLocationAPIs.LocationListPost(customLocation, token);
    response.then((result) => {
      console.log(result);
      toast.success('Location added successfully');
      setFlag(prev => !prev);
    }).catch((err) => {
      console.error('Location addition error:', err);
      toast.error(`Failed to add location: ${err.response?.data?.message || err.message}`);
    });
  };

  const handleConfirmCategory = () => {
    setOpenCustomCategory(false);
    const response = InventoryCategoryAPIs.CategoryListPost(customCategory, token);
    response.then((result) => {
      console.log(result);
      toast.success('Category added successfully');
      setFlag(prev => !prev);
    }).catch((err) => {
      console.error('Category addition error:', err);
      toast.error(`Failed to add category: ${err.response?.data?.message || err.message}`);
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Machine</DialogTitle>
      <Formik
        initialValues={{
          name: '',
          upc: '',
          manufacturer: '',
          description: ' ',
          availability: '',
          location: '',
          instances: '',
          status: '',
          purchase_cost: '',
          purchase_date: '',
          warranty_expiration: '',
          image: '',
          category: '',
          is_Hidden: false,
          supervised: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();

          // Append form values to formData
          for (const key in values) {
            if (values.hasOwnProperty(key)) {
              if (key === 'purchase_date' || key === 'warranty_expiration') {
                formData.append(key, values[key] ? values[key].format('YYYY-MM-DD') : '');
              } else {
                formData.append(key, values[key]);
              }
            }
          }

          // Check for the image and append if available
          if (values.image) {
            formData.append('image', values.image);
          }

          // Handle availability and availability1
          if (values.availability) {
            // Map the selected availability to its index
            const availabilityIndex = availabilityList.indexOf(values.availability);
            formData.append('availability', values.availability);
            formData.append('availability1', availabilityIndex.toString());
          } else {
            // Send empty strings for availability and availability1 if not set
            formData.append('availability', '');
            formData.append('availability1', '');
          }

          // API call to add the machine
          const response = MachineListAPIs.AddInventory(formData, token);
          response.then((result) => {
            console.log(result);
            toast.success('Machine added successfully');
            resetForm();
            setNewImage(null);
            onClose();
            setUpdate(prev => !prev);
          }).catch((error) => {
            console.error('Machine addition error:', error);
            if (error.response && error.response.data) {
              const errorMessages = Object.values(error.response.data).flat().join(' ');
              toast.error(`Failed to add machine: ${errorMessages}`);
            } else {
              toast.error('Failed to add machine: An unexpected error occurred.');
            }
          });
        }}
      >
        {({ handleSubmit, values, touched, errors, handleChange, setFieldValue, resetForm }) => {
          const handleFileChange = (event) => {
            const file = event.currentTarget.files[0];

            if (file) {
              // Check if file size exceeds 1MB (1MB = 1,048,576 bytes)
              const fileSizeLimit = 1048576; // 1MB in bytes
              if (file.size > fileSizeLimit) {
                toast.error('File size should not exceed 1MB');
                return;  // Do not proceed with file upload
              }

              // Check if the file is an image
              if (!file.type.startsWith('image/')) {
                toast.error('Only image files are allowed');
                return;
              }

              // If both validations pass, set the image and display a preview
              setFieldValue('image', file);
              setNewImage(URL.createObjectURL(file));
            }
          };

          const handleDiscard = () => {
            resetForm();  // Reset the form on discard
            setNewImage(null);  // Clear the image preview
            onClose();
            toast.info('Form discarded');
          };

          return (
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
              <DialogContent>
                <Grid container spacing={10}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      name="name"
                      label="Name"
                      heading="Name*"
                      sx={styledField}
                    />
                    <FormField
                      name="upc"
                      label="Enter UPC"
                      fieldType="textfield"
                      heading="UPC"
                      sx={styledField}
                    />
                    <FormField
                      name="instances"
                      label=""
                      fieldType="textfield"
                      heading="Instances*"
                      sx={styledField}
                    />
                    <FormField
                      name="manufacturer"
                      label="Enter Manufacturer Name"
                      fieldType="textfield"
                      heading="Manufacturer"
                      sx={styledField}
                    />
                    <FormField
                      name="availability"
                      label=""
                      fieldType="dropdown"
                      heading="Availability"
                      options={availabilityList}
                      sx={styledField}
                    />
                    <Box>
                      <FormField
                        name="location"
                        label="Select location"
                        fieldType="dropdown"
                        heading="Location"
                        options={locations}
                        sx={styledField}
                      />
                      <IconButton aria-label="custom-location" onClick={() => setOpenCustomLocation(true)}>
                        <AddIcon sx={{ color: 'green' }} />
                      </IconButton>
                    </Box>
                    <CustomLocationDialog
                      openCustomLocation={openCustomLocation}
                      setCustomLocation={setCustomLocation}
                      onClose={() => setOpenCustomLocation(false)}
                      onConfirm={() => {
                        setFieldValue('location', customLocation);
                        handleConfirmLocation();
                      }}
                    />
                    <FormField
                      name="purchase_cost"
                      label="Enter Purchase Cost"
                      fieldType="textfield"
                      heading="Purchase Cost"
                      sx={styledField}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>
                      Machine Image
                    </Typography>

                    {newImage ? (
                      <img src={newImage} alt="New Upload" style={{ width: '200px', height: '200px' }} />
                    ) : null}

                    <Button
                      variant="contained"
                      onClick={() => fileInputRef.current.click()}
                      sx={{
                        height: newImage ? "40px" : "100px",  // Dynamic height based on image upload
                        width: "150px",
                        padding: newImage ? "8px 12px" : "12px 16px",  // Adjusted padding for smaller button when image is uploaded
                        margin: 1,
                        background: newImage ? "#FFE6E6" : "#E6F2FF",  // Optional: change button color if image is uploaded
                        color: "#3F3F3F",
                        border: newImage ? "1px solid #FF6666" : "1px solid #66B0FF",  // Optional: change border color if image is uploaded
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column", // Stack icon above the text
                      }}
                    >
                      <Typography sx={{ fontSize: newImage ? 14 : 24, p: 0, m: 0, mt: newImage ? 0 : -1 }}>
                        {newImage ? "Change Image" : "+"}
                      </Typography>
                      {!newImage && (
                        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                          Upload File
                        </Typography>
                      )}
                    </Button>

                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />

                    {touched.image && errors.image && (
                      <FormHelperText error>{errors.image}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <FormField
                        name="category"
                        label="Select Category"
                        fieldType="dropdown"
                        heading="Category"
                        options={categories}
                        sx={styledField}
                      />
                      <IconButton aria-label="custom-category" onClick={() => setOpenCustomCategory(true)}>
                        <AddIcon sx={{ color: 'green' }} />
                      </IconButton>
                    </Box>
                    <CustomCategoryDialog
                      openCustomCategory={openCustomCategory}
                      setCustomCategory={setCustomCategory}
                      onClose={() => setOpenCustomCategory(false)}
                      onConfirm={() => {
                        setFieldValue('category', customCategory);
                        handleConfirmCategory();
                      }}
                    />
                    <FormField
                      name="purchase_date"
                      label="date"
                      fieldType="date"
                      heading="Purchase Date"
                      sx={styledField}
                    />
                    <FormField
                      name="warranty_expiration"
                      label="date"
                      fieldType="date"
                      heading="Warranty Expiration"
                      sx={styledField}
                    />
                  </Grid>
                </Grid>
                <Typography sx={{ fontSize: '1.2rem', color: "grey" }}>Description</Typography>
                <Field
                  as={TextField}
                  name="description"
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={values.description}
                  onChange={handleChange}
                  sx={{ mt: 2, mb: 1 }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleDiscard}  // Call handleDiscard on discard
                  color="primary"
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: '12px',
                    borderWidth: '1px',
                    borderColor: 'action.active',
                    '&:hover': {
                      borderWidth: '1px',
                      borderColor: 'action.active',
                    },
                    mr: 1,
                  }}
                >
                  Discard
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    color: 'common.white',
                    backgroundColor: 'primary.main',
                    borderRadius: '12px',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    },
                  }}
                >
                  Add Product
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddMachineDialog;
