import React, { useEffect, useState } from 'react';
import { Box, InputBase, IconButton, Select, MenuItem, FormControl, InputAdornment, useTheme, useMediaQuery, OutlinedInput, InputLabel, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MachineListAPIs from '../../utilities/api/machine/AdminMachineList';
import EquipmentListAPIs from '../../utilities/api/equipment/AdminEquipmentList';
import InventoryListAPIs from '../../utilities/api/inventory/AdminInventoryList';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';

const tagsList = ['Free', 'Issue', 'Purchase', 'Exhaustible', 'NonExhaustible', 'InLabOnly', 'HighValue'];

const SearchBar = ({ activeTab, searchQuery, setSearchQuery, category, setCategory, group, setGroup, tag, setTag }) => {
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);  // State to control the dialog
  const [uploadedImages, setUploadedImages] = useState([]);  // State to store uploaded images
  const [isDragging, setIsDragging] = useState(false);  // State to manage drag status

  const user = useSelector(state => state.user.user);
  const tokens = {
    refresh_token: user.refresh,
    access_token: user.access
  };

  // Effect to handle API calls and reset filters when activeTab changes
  useEffect(() => {
    // Reset filters when the activeTab changes
    setCategory([]);
    setGroup([]);
    setTag([]);

    setLoading(true);
    setError(null);
    let apiCall;
    switch (activeTab) {
      case 0:
        apiCall = MachineListAPIs.MachineListGet();
        break;
      case 1:
        apiCall = EquipmentListAPIs.EquipmentListGet();
        break;
      case 2:
        apiCall = InventoryListAPIs.InventoryListGet(tokens);
        break;
      default:
        break;
    }

    if (apiCall) {
      apiCall.then((result) => {
        const uniqueCategories = Array.from(new Set(result.map(item => item.category))).filter(Boolean);  // Remove null/undefined
        setCategories(uniqueCategories);

        const uniqueGroups = Array.from(new Set(result.map(item => item.group))).filter(Boolean);  // Remove null/undefined
        setGroups(uniqueGroups);

        const allTags = result.reduce((acc, item) => {
          const tagsArray = [item.tag_1, item.tag_2, item.tag_3, item.tag_4].filter(Boolean);
          return acc.concat(tagsArray);
        }, []);
        const uniqueTags = Array.from(new Set(allTags)).filter(Boolean);  // Remove null/undefined
        setTags(uniqueTags);
      })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [activeTab]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleGroupChange = (event) => {
    setGroup(event.target.value);
  };

  const handleTagChange = (event) => {
    setTag(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearchSubmit();
    }
  };

  const handleSearchSubmit = () => {
    console.log(`Searching for: ${searchQuery} in category: ${category}`);
  };

  const handleAddLayoutImage = () => {
    setOpenDialog(true);  // Open dialog when Add Layout Image button is clicked
  };

  const handleDialogClose = () => {
    setOpenDialog(false);  // Close dialog
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);  // Show dragging state
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);  // Hide dragging state
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const files = Array.from(event.dataTransfer.files);
    handleFileUpload(files);
  };

  const handleFileUpload = (files) => {
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    setUploadedImages([...uploadedImages, ...imageFiles]);  // Add new images to the existing list
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    handleFileUpload(files);  // Handle file selection through input
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="form"
      onKeyDown={handleKeyDown}
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: isMobile ? 1 : 0,
        width: '100%',
        padding: theme.spacing(1),
        margin: 'none',
        marginBlock: '1rem',
        boxSizing: 'border-box'
      }}
    >
      <FormControl sx={{ minWidth: 120 }} >
        <InputLabel shrink sx={{ marginLeft: '18px' }}>Select Categories</InputLabel>
        <Select
          value={category}
          onChange={handleCategoryChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ mr: activeTab === 2 ? 20 : 45.5, height: '3rem', width: activeTab === 2 ? '90%' : '50%', ml: '1rem' }}
          multiple
          input={<OutlinedInput label="Select Categories" />}
        >
          {categories.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {activeTab === 2 && (
        <>
          <FormControl sx={{ minWidth: 80 }}>
            <InputLabel shrink sx={{ marginLeft: '18px' }}>Group</InputLabel>
            <Select
              value={group}
              onChange={handleGroupChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ mr: 5, height: '3rem', width: '75%', ml: '1rem' }}
              multiple
              input={<OutlinedInput label="Select Categories" />}
            >
              {groups.map((grp) => (
                <MenuItem key={grp} value={grp}>
                  {grp}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 80 }}>
            <InputLabel shrink sx={{ marginLeft: '18px' }}>Tags</InputLabel>
            <Select
              value={tag}
              onChange={handleTagChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ mr: 5, height: '3rem', width: '100%', ml: '1rem' }}
              multiple
              input={<OutlinedInput label="Tags" />}
            >
              {tags.map((tg) => (
                <MenuItem key={tg} value={tg}>
                  {tg}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <InputBase
        sx={{
          ml: 50,
          mr: 1,
          flex: 1,
          height: '3rem',
          padding: 'none',
          border: '#B5B5B5 solid 0.5px',
          borderRadius: '10px',
          bgcolor: '#F4F4F4',
          '& .MuiInputBase-input': {
            paddingLeft: '.1rem',
          },
        }}
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        value={searchQuery}
        onChange={handleSearchChange}
        startAdornment={
          <InputAdornment position="start">
            <IconButton
              sx={{ paddingLeft: '10px' }}
              aria-label="toggle password visibility"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      {activeTab === 3 && (
        <IconButton
          variant="contained"
          color="primary"
          sx={{ ml: 2, height: '3rem', background: "white", color: '#3F3F3F', border: '2px solid #B5B5B5', borderRadius: '12px' }}
          onClick={handleAddLayoutImage}
        >
          <AddIcon sx={{ border: '2px solid #3f3f3f', borderRadius: '50%', mr: 1 }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: '16px' }}>Add Layout Image</Typography>
        </IconButton>
      )}

      {/* Dialog for Image Upload */}
      <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>Upload Layout Image</DialogTitle>
        <DialogContent>
          <Box
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              border: '2px dashed #B5B5B5',
              padding: '1rem',
              borderRadius: '10px',
              textAlign: 'center',
              cursor: 'pointer',
              height: '150px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: isDragging ? '#f0f0f0' : 'transparent',
            }}
          >
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleFileSelect}
              style={{ display: 'none' }}  // Hidden file input for clicking to upload
              id="image-upload"
            />
            <label htmlFor="image-upload">
              {isDragging ? (
                <Typography>Drop the images here...</Typography>
              ) : (
                <Typography>Drag & drop images here, or click to select images</Typography>
              )}
            </label>
          </Box>
          <Box sx={{ marginTop: '1rem' }}>
            {uploadedImages.map((file, index) => (
              <Typography key={index}>{file.name}</Typography>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button variant="contained" onClick={handleDialogClose}>Upload</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SearchBar;
