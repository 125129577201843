import { axiosApiService } from "../axios";

const WorkbenchAPIs = {
    // Method to fetch a list of workbenches
    async getList() {
        try {
            const response = await axiosApiService.get('workbenches/');
            console.log('WorkbenchAPIs getList response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching the workbench list:', error);
            throw error;
        }
    },

    // Method to fetch details of a specific workbench by ID
    async getDetail(id) {
        try {
            const response = await axiosApiService.get(`workbenches/${id}/`);
            console.log(`WorkbenchAPIs getDetail response for ID ${id}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error fetching the workbench detail for ID ${id}:`, error);
            throw error;
        }
    },

    // Method to create a new workbench
    async createWorkbench(data) {
        try {
            const response = await axiosApiService.post('workbenches/', data);
            console.log('WorkbenchAPIs createWorkbench response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error creating a new workbench:', error);
            throw error;
        }
    },

    // Method to update an existing workbench by ID
    async updateWorkbench(id, data) {
        try {
            const response = await axiosApiService.put(`workbenches/${id}/`, data);
            console.log(`WorkbenchAPIs updateWorkbench response for ID ${id}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error updating the workbench with ID ${id}:`, error);
            throw error;
        }
    },

    // Method to delete a specific workbench by ID
    async deleteWorkbench(id) {
        try {
            const response = await axiosApiService.delete(`workbenches/${id}/`);
            console.log(`WorkbenchAPIs deleteWorkbench response for ID ${id}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error deleting the workbench with ID ${id}:`, error);
            throw error;
        }
    }
};

export default WorkbenchAPIs;
